import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {
  ArrowRightCircleIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { Job } from 'generated/apolloComponents'
import JobListItemLogo from './JobListItemLogo'
import JobListItemDetails from './JobListItemDetails'
import style from './JobListItem.module.scss'
import JobListItemTitle from './JobListItemTitle'
import JobListItemSalary from './JobListItemSalary'
import JobListItemTags from './JobListItemTags'
import JobListItemSave from './JobListItemSave'
import JobPosition from './JobPosition'
import DropdownString from '../DropdownString'
import SkillsPills from '@/components/atoms/JobListItem/SkillsPills'

interface iProps {
  job: Job
}

const GSListItem = ({ job }: iProps) => {
  const { t } = useTranslation('common')

  const locations = []

  if (job.remote) locations.push('Remote')
  if (job.city && job.city.length > 0)
    locations.push(...(job.city?.split(',') as string[]))
  const count = locations.length

  return (
    <Link
      href={job.redirectTo as string}
      prefetch={false}
      target='_blank'
      rel='nofollow'
      className={`${style.item} ${style.gs_item}`}
      data-cy='offer-card'
    >
      <JobListItemLogo job={job} />
      <div className={`${style.item__title}`}>
        <h3>
          <JobPosition position={job.position as string} />
        </h3>
        <div className='mt-2 flex items-center'>
          <div className='text-xxs uppercase text-neutral-800 font-semibold tracking-wider'>
            {job.company.name}
          </div>
        </div>
      </div>
      <div className={` ${style.item__details}`}>
        {locations.length > 0 && (
          <DropdownString
            icon
            title={`${locations[0]} +${count - 1}`}
            list={locations}
          />
        )}
      </div>
      <div className={`${style.item__salary}`} />
      <JobListItemTags job={job} />
      <div className={`${style.item__save}`}>
        <div className='absolute right-3 top-1 md:relative lg:h-full flex items-center'>
          <ArrowTopRightOnSquareIcon width={28} className='text-neutral-400' />
        </div>
      </div>
      {/* <JobListItemLogo job={job} />
      <JobListItemTitle job={job} />
      <JobListItemDetails job={job} />
      <JobListItemSalary job={job} />
      <JobListItemTags job={job} />
      <JobListItemSave job={job} isLiked={isLiked} setIsLiked={setIsLiked} /> */}
    </Link>
  )
}

export default GSListItem
